import React, { useContext, useState } from "react";
import { TransactionContext } from "../context/TransactionsContext";

const WalletModal = () => {
  const {
    connectWalletModal,
    setConnectWalletModal,
    connectMetamask,
    connectWalletConnect,
  } = useContext(TransactionContext);
  return (
    <div className=" ">
      {connectWalletModal && (
        <div
          id="walletModal"
          onClick={() => setConnectWalletModal(false)}
          className="h-screen w-screen overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-0 flex align-middle z-50 justify-center items-center  md:h-full md:inset-0 bg-black bg-opacity-40"
        >
          <div className=" flex align-middle justify-center text-center  px-4 w-full max-w-md  mx-auto">
            {/* <!-- Modal content --> */}
            <div className="relative  rounded-lg shadow bg-[#1f1f1f]">
              {/* <!-- Modal header --> */}
              <div className=" py-4 px-6 rounded-t  bg-[#2692ff] ">
                <h3 className="text-base font-bold font-OwnersWide text-white leading-8 text-center">
                Please connect your Wallet
                </h3>
              </div>
              {/* <!-- Modal body -->  */}
              <div className="p-6 bg-[#fff] ">
                <button
                  className="  bg-[#ffd600]  px-5 py-2  rounded-xl text-sm font-semibold font-OwnersWide tracking-wide cursor-pointer w-full  block"
                  id="walletConnect"
                  onClick={() => connectMetamask()}
                >
                  Connect to MetaMask
                </button>
                <button
                  className=" bg-[#ffd600]  px-5 py-2 whitespace-nowrap mt-4 rounded-xl text-sm font-semibold font-OwnersWide tracking-wide cursor-pointer w-full  block"
                  id="walletConnect"
                  onClick={() => connectWalletConnect()}
                >
                  Connect to WalletConnect
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletModal;
