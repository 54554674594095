import { API_BASE_TOKEN, API_BASE_URL } from "../constant/apiConstants";
import { errorToast, successToast } from "./util";

export const handleCreateNFT = async (payload) => {
  console.log(payload)
  try {
    const res = await fetch(API_BASE_URL, {
      method: 'POST',
      headers: {
        Authorization: API_BASE_TOKEN,
      },
      body: payload
    });
    const result = await res.json();
    if (res.status >= 400) {
      errorToast('Your art couldn\'t upload. Please try again !!')
      console.log("404 error", res)
    } else {
      successToast('Your art is successfully upload.')
      console.log(result)
    }
  } catch (e) {
    console.log(e)
  }

};