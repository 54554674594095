import React from 'react';

const CircularBtn = ({
  onClick, additionalClasses, text, children,
}) => (

  <button
    onClick={onClick}
    type="button"
    className={`flex items-center justify-center ${additionalClasses}`}
  >
    {text}
    <span>
      {children}
    </span>
  </button>
);
export default CircularBtn;
