import Hamburger from '../assets/images/Hamburger.svg'
import Close from '../assets/images/Close.svg'
const Navbar = ({isHamburgerMenuOpen,setIsHamburgerMenuOpen}) => {

    return (
        <div className="flex justify-between text-white font-OwnersWide font-bold text-xl ">
            <p className='hidden md:block'>Sunflowers DAO</p>
            <img
                className="cursor-pointer ml-auto"
                src={isHamburgerMenuOpen ? Close : Hamburger}
                alt="Hamburger"
                onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)} />
        </div>
    )
}

export default Navbar