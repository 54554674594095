import { useState } from "react";
import cn from "classnames";
import "./Flip.css";
import sunflowerBackground from "../assets/images/sunflower-background.svg";
import { capitalizeFirstLetter } from "../helpers/util";
import { IoLogoTwitter } from "react-icons/io";
import { IoGlobeOutline } from "react-icons/io5";
function FlipCard({ art }) {
  console.log(art.nft_name);
  const [showBack, setShowBack] = useState(false);

  function handleClick() {
    setShowBack(!showBack);
  }

  return (
    <div className="flip-card-outer" onClick={handleClick}>
      <div
        className={cn("flip-card-inner", {
          showBack,
          "hover-trigger": art.variant === "hover",
        })}
      >
        <div className="card front h-full bg-white">
          <div className="  p-4 pb-10">
            <img
              src={art.nft_media}
              alt={art.nft_name}
              className='w-full h-[300px] md:h-[450px] md:max-h-[450px] object-cover '
            />
            {/* <img src={sunflowerBackground} alt='Sunflower Dao' /> */}
            <div className="text-center">
              <h1 className="text-2xl  mt-5 text-black  font-OwnersWide font-bold">{art.nft_name}</h1>
              <h2 className="text-xl font-WorkSans uppercase text-gray-400 font-medium ">
                {capitalizeFirstLetter(art.artist.artist_name)}
              </h2>
              <div className="mt-5 text-white text-base  font-semibold font-OwnersWide">
                {art.place_bid_url ? (
                  <a
                    href={art.place_bid_url}
                    target="_blank"
                    rel="noreferrer"
                    className="mx-auto bg-[#2692FF] px-10 py-2 rounded-full cursor-pointer"
                  >
                    Place Bid
                  </a>
                ) : (
                  <span className="mx-auto text-[#2692FF] px-10 py-2 rounded-full cursor-not-allowed ">
                    SOLD
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card back">
          <div className="back bg-white  h-[300px] md:h-[550px] p-5">
            <div className="flex">
              <img
                src={art.artist.artist_image}
                alt={art.artist.artist_name}
                className="min-w-[100px] min-h-[100px] max-h-[100px] max-w-[100px] 
                md:min-w-[150px] md:min-h-[150px] md:max-h-[150px] md:max-w-[150px] object-cover bg-blue-400  "
              />

              <div className="ml-2 max-w-[65%]">
                <h1 className="text-base md:text-lg font-OwnersWide font-bold  break-words  max-w-full">
                  {capitalizeFirstLetter(art.artist.artist_name)}
                </h1>
                <div className="flex">
                  {art.artist.artist_twitter_link && (
                    <a
                      href={art.artist.artist_twitter_link}
                      target="_blank"
                      rel="noreferrer"
                      className=" cursor-pointer text-3xl p-2 "
                    >
                      <IoLogoTwitter />{" "}
                    </a>
                  )}
                  {art.artist.artist_website && (
                    <a
                      href={art.artist.artist_website}
                      target="_blank"
                      rel="noreferrer"
                      className=" cursor-pointer text-3xl mx-3 p-2"
                    >
                      <IoGlobeOutline />
                    </a>
                  )}
                </div>
              </div>
            </div>

            <p className="mt-2 text-base font-WorkSans tracking-normal leading-normal">{art.artist.artist_bio}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;
