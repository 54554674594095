import React, { useEffect, useState } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'


const Accordion = (props) => {
    const {
        heading, headingClass, preOpen, h1Class,
    } = props;

    const [clicked, setClicked] = useState(preOpen || false);

    useEffect(() => {
        if ('preOpen' in props) {
            setClicked(Boolean(preOpen));
        }
    }, [preOpen]);

    return (
        <div className=" border border-blue-400 tracking-wider  mx-auto">
            <div
                role="button"
                className={`wrap flex justify-between ${headingClass}`}
                onClick={() => setClicked(!clicked)}>
                <h1 className={h1Class}>{heading}</h1>
                <span>
                    {!clicked ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                </span>
            </div>
            {clicked && props.children}
        </div>

    );
};

export default Accordion;
