export const contractAddress = "0xee50d5A8CF42B6b7B8a5466a5b1dFfCAd22E1fc1";

export const infura_RPC_URL_ETH_Mainnet= 'https://mainnet.infura.io/v3/52eefdc8138e46f1b3742e78b0bf0e9e'
export const infura_RPC_URL_ETH_Rinkeby= 'https://rinkeby.infura.io/v3/52eefdc8138e46f1b3742e78b0bf0e9e'

export const infura_RPC_URL_Polygon_Mainnet =
  "https://polygon-mainnet.infura.io/v3/91c4e708571847948bb37155c7fd25fc";
export const infura_RPC_URL_Polygon_Mumbai =
  "https://polygon-mumbai.infura.io/v3/91c4e708571847948bb37155c7fd25fc";

export const network_id = {
  mumbai_polygon: 0x13881,
  mainnet_polygon: 0x89,
  rinkeby_eth: 0x4,
  mainnet_eth: 0x1,
};

export const faqs = [
  {
    question: "Who launched The Sunflowers 4 Ukraine?",
    answer:
      "The Sunflowers 4 Ukraine is being put forth by a coalition of reputable nonprofits, foundations, and web3 communities. The project was created via a partnership between Galaxy Labs, Gotham Labs, and Choose Love.",
  },
  {
    question: "How Do I Know This Money Supports Ukraine? ",
    answer:
      "All revenue generated by The Sunflowers 4 Ukraine will be passed on to efforts on the ground vetted by our partner  <a class='underline' target='_blank' href='https://choose.love/pages/about-choose-love'> Choose Love</a>, a global leader in vetting and funding local efforts that support displaced people and those living in conflict.",
  },
  {
    question: "Why Not Just Deploy All The Funds At Once? ",
    answer:
      "We chose to go with intervals because we do not know the outcome of the conflict. We want to be able to support people in Ukraine now, but also on the long road to recovery from this conflict. ",
  },
  {
    question: "Can I Donate Without Crypto? ",
    answer:
      "Yes. Choose Love is accepting donations in USD. Click here to <a class='underline' target='_blank' href='https://choose.love/pages/about-choose-love'> donate</a>.",
  },
  {
    question: "How Do I Vote?  ",
    answer:
      "We will be using Snapshot to vote, and every wallet gets one vote, no matter how many NFTs they hold. At each distribution interval, the community is presented with approximately 10 vetted options provided by <a class='underline' target='_blank' href='https://choose.love/pages/about-choose-love'> Choose Love</a>, and DAO members may cast a vote for their top four (4) choices. The 4 options that receive the most votes, in the aggregate, will each receive 25% of the total cash disbursements tied to that interval.",
  },{
    question: "Carbon Offsets? ",
    answer:
      "We're going to offset the entire lifecycle of the NFT by partnering with <a href='https://www.b-e-f.org/' target='_blank'> b-e-f.org</a>",
  },
];
 
const networks = {
  ethereum: {
    chainId: `0x1`,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    blockExplorerUrls: ["https://etherscan.io"]
  },
  polygon: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"]
  },
  bsc: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18
    },
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org"
    ],
    blockExplorerUrls: ["https://bscscan.com"]
  }
};