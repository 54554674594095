import React from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import FlipCard from "../components/FlipCard";
const FeaturedArtist = ({ nftArray }) => {
  let newArrays = [];
  let width;
  // Get the Width of the screen
  if (window.screen.availWidth) {
    width = window.screen.availWidth;
  }
  // To define the size of array
  const arraySize = width > 700 ? 9 : 1;
  // Creating new array of array of 4
  for (let i = 0; i < nftArray.length; i += arraySize) {
    newArrays.push(nftArray.slice(i, i + arraySize));
  }
  // Set number of slides to be shown
  const slidesToBeRendered =
    width > 700
      ? Math.ceil(nftArray.length / arraySize)
      : nftArray.length ;

  return (
    <div className="pt-5 pb-20 new-container">
      <h1 className="font-OwnersWide font-bold text-3xl text-white text-center">
        All Artists
      </h1>

      <p className="font-WorkSans tracking-normal text-white text-center mt-4 lg:w-2/5 mx-auto mg-10 md:mb-16">
        All artwork is on auction through our partner, Nifty Gateway. Click on
        each card to view the author’s bio.
      </p>
      <CarouselProvider
        visibleSlides={1}
        totalSlides={slidesToBeRendered}
        step={1}
        isIntrinsicHeight
        infinite
        interval={10000}
        isPlaying
        lockOnWindowScroll
        playDirection="forward"
      >
        <Slider>
          {newArrays.map((nftSlide, idx2) => (
            <Slide index={idx2} key={idx2}>
              <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                {nftSlide.map((art, idx) => (
                  <FlipCard key={idx} art={art} />
                ))}
              </div>
            </Slide>
          ))}
        </Slider>

        <ButtonBack>
          <div className="bg-black absolute left-0 bottom-1/2 opacity-20 hover:opacity-80">
            {/* <ChevronLeftIcon className='w-10 h-10  text-white' /> */}
          </div>
        </ButtonBack>
        <ButtonNext>
          <div className="bg-black absolute right-0 bottom-1/2 opacity-20 hover:opacity-80 ">
            {/* <ChevronRightIcon className='w-10 h-10 text-white ' /> */}
          </div>
        </ButtonNext>

        <div className="hidden md:flex justify-center">
          <DotGroup>
            <Dot slide={4} />
          </DotGroup>
        </div>
      </CarouselProvider>
    </div>
  );
};

export default FeaturedArtist;
