import React from 'react'
import { Navsections } from '../constant/constant'

function HamburgerMenuCard({handleOnMenuBtnClick}) {
  return (
    <div className='customBlue absolute w-1/2 md:w-1/4 h-min right-4 mt-16 py-6 px-3 rounded-lg z-40'>
        {Navsections.map((section,idx)=>(
            <p key= {idx} onClick={()=>handleOnMenuBtnClick(idx)} className='text-white cursor-pointer font-WorkSans text-lg md:text-4xl mb-6 border-white border-b '>{section}</p>
        ))}
    </div>
  )
}

export default HamburgerMenuCard