import React, { useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { capitalizeFirstLetter } from "../helpers/util";

const EmblaCarousel = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  // Image Showing In Left Slide
  const leftSide = () => {
    return current === 0 ? length - 1 : current - 1;
  };
  // Image Showing In Right Slide
  const rightSlide = () => {
    return length - 1 === current ? 0 : current + 1;
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <div className="relative">
      <HiChevronLeft className="left-arrow" onClick={prevSlide} />
      <HiChevronRight className="right-arrow" onClick={nextSlide} />
      <section className="slider new-container w-11/12 md:w-3/4">
        {/* Left Image */}
        <div className="image-left image-card">
          <img
            src={slides[leftSide()].nft_media}
            alt={slides[leftSide()].nft_name}
            className="image"
          />
          <h1 className="text-lg md:text-xl font-OwnersWide font-bold">{capitalizeFirstLetter(slides[leftSide()].nft_name)}</h1>
          <p className="text-base md:text-lg font-WorkSans font-normal uppercase text-gray-500">
            {slides[leftSide()].artist.artist_name}
          </p>
        </div>
        {/* Right Image */}
        <div className="image-right image-card">
          <img
            src={slides[rightSlide()].nft_media}
            alt={slides[rightSlide()].nft_name}
            className="image "
          />
          <h1 className="text-lg md:text-xl font-OwnersWide font-bold">
            {slides[rightSlide()].nft_name}
          </h1>
          <p className="text-base md:text-lg font-WorkSans font-normal uppercase text-gray-500">
            {slides[rightSlide()].artist.artist_name}
          </p>
        </div>
        {/* Main Slide */}
        {slides.map((slide, index) => {
          return (
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
            >
              {index === current && (
                <div className="image-card">
                  <img
                    src={slide.nft_media}
                    alt={slide.nft_name}
                    className="image "
                  />
                  <h1 className="text-lg md:text-xl font-OwnersWide font-bold">{slide.nft_name}</h1>
                  <p className="text-base md:text-lg font-WorkSans font-normal uppercase text-gray-500">{slide.artist.artist_name}</p>
                </div>
              )}
            </div>
          );
        })}
      </section>
    </div>

  );
};

export default EmblaCarousel;
