import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { abi } from "../Constants/abi";
import {
  contractAddress,
  infura_RPC_URL_ETH_Mainnet,
  network_id,
} from "../Constants/constants";
import { loadingToast, successToast, errorToast } from "../helpers/util";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { API_BASE_TOKEN, API_BASE_URL } from "../constant/apiConstants";

export const TransactionContext = React.createContext();

let eth;

if (typeof window !== "undefined") {
  eth = window.ethereum;
}

const web3 = new Web3(infura_RPC_URL_ETH_Mainnet);

const getEthereumContract = () => {
  var contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const TransactionProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [saveUkraineFactoryContract, setSaveUkraineFactoryContract] = useState(
    getEthereumContract()
  );
  const [chainId, setChainId] = useState();
  const [isSaleStarted, setIsSaleStarted] = useState(null);
  const [getPrices, setGetPrices] = useState(null);
  const [networkChain, setNetworkChain] = useState(network_id.mainnet_eth);
  const [totalFundRaisedAmount, setTotalFundRaisedAmount] = useState(null);
  const [navBookmarkLink, setNavBookmarkLink] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [tweetButtonShow, SetTweetButtonShow] = useState(false)
  const web3Provider = React.useRef();

  const [cryptoWalletConnectedBy, setCryptoWalletConnectedBy] = useState(null);
  const [isSubmitYourModalArtOpen, setIsSubmitYourModalArtOpen] = useState(false)

  /**
   * Checks if MetaMask is installed and an account is connected
   * @param {*} metamask Injected MetaMask code from the browser
   * @returns
   */
  const checkIfWalletIsConnected = async (metamask = eth) => {
    try {
      if (!metamask) return loadingToast("Please install metamask ");
      const accounts = await metamask.request({ method: "eth_accounts" });
      setUserAndLoadContract(metamask, accounts);
    } catch (error) {
      console.error(error);
      throw new Error("No ethereum object.");
    }
  };

  /**
   * Prompts user to connect their MetaMask wallet
   * @param {*} metamask Injected MetaMask code from the browser
   */
  const connectWallet = async (metamask = eth) => {
    try {
      if (!metamask) return isLoading("Please install metamask ");
      const accounts = await metamask.request({
        method: "eth_requestAccounts",
      });
      setIsLoading(true);
      setUserAndLoadContract(metamask, accounts);
      setIsLoading(false);
      successToast("Your wallet is successfully connected");
    } catch (error) {
      console.error(error);
      errorToast(error);
    }
  };

  async function setUserAndLoadContract(metamask, accounts) {
    try {
      if (!accounts.length) {
        connectWallet();
      }
      setCurrentAccount(accounts[0]);
      validateChain(metamask);
      const contract = getEthereumContract();
      setSaveUkraineFactoryContract(contract);
    } catch (err) {
      console.error("Failed to set and load contract", err);
    }
  }

  async function validateChain(metamask = eth) {
    const fetchChainId = metamask.chainId;
    setChainId(fetchChainId == networkChain ? true : false);

    if (!fetchChainId) {
      loadingToast("Please Install the metamask");
    }
    // Check chain id
    // if (fetchChainId != networkChain) {
    //   loadingToast(
    //     "Please wait, while the metamask is connecting with Ethereum Mainnet"
    //   );
    //   try {
    //     await window.ethereum.request({
    //       method: "wallet_addEthereumChain",
    //       params: [
    //         {
    //           chainId: `0x1`,
    //           chainName: "Ethereum Mainnet",
    //           nativeCurrency: {
    //             name: "Ether",
    //             symbol: "ETH",
    //             decimals: 18,
    //           },
    //           rpcUrls: [
    //             "https://mainnet.infura.io/v3/",
    //             "wss://mainnet.infura.io/ws/v3/",
    //             "https://api.mycryptoapi.com/eth",
    //             "https://cloudflare-eth.com",
    //           ],
    //           blockExplorerUrls: ["https://etherscan.io"],
    //         },
    //       ],
    //     });
    //     window.location.reload();
    //   } catch (err) {
    //     errorToast(err?.message);
    //     console.error("Error======", err);
    //   }
    // }
  }

  async function checkSaleStarted() {
    // const prices  = await saveUkraineFactory.methods.getPrices().call();
    const saleOpen = await saveUkraineFactoryContract.methods
      .isSaleOpen()
      .call();
    setIsSaleStarted(saleOpen);
  }

  async function getAllPrices() {
    const prices = await saveUkraineFactoryContract?.methods
      ?.getPrices()
      .call();
    setGetPrices(prices);
  }

  async function fundRaised() {
    const fund = await saveUkraineFactoryContract?.methods
      ?.fundsRaised()
      .call();
    setTotalFundRaisedAmount(fund);
  }

  // Metamask Connection
  const connectMetamask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setCurrentAccount(accounts[0]);
        successToast('Your account is connected')
        web3Provider.current = window.ethereum;
        window.webx = web3Provider.current;
      } catch (e) {
        console.log(e);
      }
    } else {
      errorToast("Please Install Metamask");
    }
  };

  const connectWalletConnect = async () => {
    web3Provider.current = null;
    setCurrentAccount(null);
    try {
      const RPC_URLS = {
        1: "https://mainnet.infura.io/v3/52eefdc8138e46f1b3742e78b0bf0e9e",
        4: "https://rinkeby.infura.io/v3/52eefdc8138e46f1b3742e78b0bf0e9e",
      };
      const walletConnector = new WalletConnectProvider({
        rpc: {
          1: RPC_URLS[1],
          4: RPC_URLS[4],
        },
        qrcode: true,
        pollingInterval: 15000,
      });

      // Show QR Code
      const accounts = await walletConnector.enable();
      const provider = new Web3Provider(walletConnector, "any");
      web3Provider.current = provider.provider;
      setCurrentAccount(accounts[0]);
      successToast('Your account is connected')
    } catch (ex) {
      console.log(ex);
    }
  };

  // Wallet Connect Minting
  const mintToken = async (
    priceOfNFT = 100000000000000000,
    tokenId = 0,
    noOfMinted = 1,
    provider = web3Provider.current
  ) => {
    console.log(currentAccount)
    if (!provider) {
      window.scrollTo(0, 0);
      return errorToast("Please Connect to your wallet ");
    }
    let id = undefined;

    try {
      // Check the Current Account
      // if (!currentAccount) return setConnectWalletModal(true);
      if (isSaleStarted) {
        let finalPrice = priceOfNFT * noOfMinted;
        const data = await saveUkraineFactoryContract.methods
          ?.claim(noOfMinted, tokenId)
          .encodeABI();
        let parms = {
          to: contractAddress,
          from: currentAccount,
          data: data,
          value: Number(finalPrice).toString(16),
        };


        id = setTimeout(() => {
          window.location.reload();
        }, 60000); // 10 sec
        const txhash = await provider.request({
          method: "eth_sendTransaction",
          params: [parms],
        });
        loadingToast("Please wait your transaction in process");
        clearTimeout(id);
        console.log("txhash", txhash);
        setIsModalOpen(true);
        successToast(`You have successfully minted ${noOfMinted} token`);
      } else {
        errorToast("Sale is not started yet");
      }
      setIsLoading(false);
    } catch (error) {
      clearTimeout(id);
      errorToast('Unable to process your request at the moment. Please try again later')
      console.error("Failed to mint", error);
    }
  };

  // Set the connected wallet on page reload
  const ethEnabled = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then(handleAccountsChanged)
        .catch((err) => {
          console.error(err, "NOT CONNECTED");
        });

      window.ethereum.on("accountsChanged", handleAccountsChanged);
      function handleAccountsChanged(accounts) {
        if (accounts.length === 0) {
          console.log("Please connect to MetaMask.");
          setCurrentAccount('')
          // window.location.reload()
        } else if (accounts[0] !== currentAccount) {
          setCurrentAccount(accounts[0]);
          web3Provider.current = window.ethereum
          console.log(accounts);
        }
      }
      return true;
    }
    return false;
  };
  const resetCurrentWalletAddress=()=>{
    setCurrentAccount()
  }
  window?.ethereum?.on("disconnect", resetCurrentWalletAddress);


  const disconnectMetaMask = () => {
    web3Provider.current = null;
    setCurrentAccount(null);
  };

  useEffect(() => {
    if (saveUkraineFactoryContract) {
      ethEnabled();
      checkSaleStarted();
    }
  }, [saveUkraineFactoryContract]);

  useEffect(() => {
    if (isSaleStarted) {
      getAllPrices();
      fundRaised();
    }
  }, [isSaleStarted]);

  return (
    <TransactionContext.Provider
      value={{
        connectWallet,
        connectWalletModal,
        setConnectWalletModal,
        currentAccount,
        isLoading,
        isSaleStarted,
        chainId,
        getPrices,
        totalFundRaisedAmount,
        setNavBookmarkLink,
        navBookmarkLink,
        setIsModalOpen,
        isModalOpen,
        mintToken,
        connectMetamask,
        connectWalletConnect,
        cryptoWalletConnectedBy,
        setCryptoWalletConnectedBy,
        disconnectMetaMask,
        tweetButtonShow,
        isSubmitYourModalArtOpen, setIsSubmitYourModalArtOpen
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};
