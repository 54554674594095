import React, { useContext, useState } from "react";
import Camera from "../assets/images/Camera.svg";
import { TransactionContext } from "../context/TransactionsContext";
import { handleCreateNFT } from "../helpers/helpers";
import { errorToast } from "../helpers/util";

function FormModal({ setIsFormModalOpen }) {
  const {
    currentAccount,
    isSubmitYourModalArtOpen,
    setIsSubmitYourModalArtOpen,
  } = useContext(TransactionContext);

  const [imageUpload, setImageUpload] = useState(null);
  const [data, setData] = useState(new FormData());
  const [nftDetails, setNftDetails] = useState({
    nft_name: "",
    nft_artist_name: "",
    artist_email: "",
    artist_portfolio_link: "",
    artist_wallet_address: "",
  });
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();
    data.append("nft_media", file);
    // To preview the image in the frontend
    reader.onloadend = () => {
      setImageUpload(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!imageUpload) return errorToast("Please Upload NFT Art");
    data.append("nft_name", nftDetails.nft_name);
    data.append("nft_artist_name", nftDetails.nft_artist_name);
    if (nftDetails.artist_email) {
      data.append("artist_email", nftDetails.artist_email)
    }
    if (nftDetails.artist_portfolio_link) {

      data.append("artist_portfolio_link", nftDetails.artist_portfolio_link);
    }



    data.append("artist_wallet_address", currentAccount);
    console.log(data);
    handleCreateNFT(data);
    setIsSubmitYourModalArtOpen(false);
  };

  const handleNFTDetailsChange = (e) => {
    const { id, value } = e.target;
    setNftDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <>
      {isSubmitYourModalArtOpen && (
        <div
          id="walletModal"
          className="h-screen w-screen overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-0 flex align-middle z-50 justify-center items-center  md:h-full md:inset-0 bg-black bg-opacity-40"
        >
          <div className=" flex align-middle justify-center   w-full  mx-auto my-20">
            {/* <!-- Modal content --> */}
            <div className="relative  rounded-lg shadow bg-white w-[90%] md:w-[50%]">
              {/* <!-- Modal header --> */}
              <div className=" py-4 px-6  flex justify-between   ">
                <h3 className="text-base font-EuroBold font-semibold  lg:text-xl pt-2 ">
                  Upload Your Art
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  onClick={() => setIsSubmitYourModalArtOpen(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body -->  */}
              <div className="px-2 pt-3 pb-6 bg-white ">
                <form
                  className=" pb-0 space-y-4 px-4"
                  onSubmit={handleSubmit}
                >

                  {/* Image */}
                  <div>
                    <div>
                      <input
                        type="file"
                        id="nft-file"
                        accept="image/*"
                        hidden
                        onChange={handleImageChange}
                      />

                      <div className="flex flex-column mr-2 ">
                        <label
                          style={{
                            backgroundImage: `url(${imageUpload ? imageUpload : Camera
                              })`,
                            backgroundColor: 'black',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                          }}
                          htmlFor={!imageUpload ? "nft-file" : ""}
                          className="upload-art-image-btn border rounded-full border-gray-500 bg-white"
                          alt="avatar image"
                        ></label>
                      </div>

                      {imageUpload && (
                        <>
                          <label
                            className="text-blue-500 border-blue-500 border-b-2  cursor-pointer text-center"
                            htmlFor="nft-file"
                          >
                            Change Image
                          </label>
                        </>
                      )}
                    </div>

                  </div>
                  <div className="grid grid-cols-12 gap-4 md:gap-x-4">


                    {/* NFT Name */}
                    <div className="col-span-12 md:col-span-6">
                      <label
                        htmlFor="nft_artist_name"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="nft_name"
                        id="nft_name"
                        placeholder="Name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        onChange={(e) => handleNFTDetailsChange(e)}
                        required
                      />
                    </div>
                    {/* Artist Name */}
                    <div className="col-span-12 md:col-span-6">
                      <label
                        htmlFor="nft_artist_name"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Artist Name
                      </label>
                      <input
                        type="text"
                        name="nft_artist_name"
                        id="nft_artist_name"
                        placeholder="Artist name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        onChange={(e) => handleNFTDetailsChange(e)}
                        required
                      />
                    </div>
                  </div>
                  {/* Email */}
                  <div className="-mt-20">
                    <label
                      htmlFor="nft_artist_name"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="artist_email"
                      id="artist_email"
                      placeholder="Email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      onChange={(e) => handleNFTDetailsChange(e)}
                    />
                  </div>
                  {/* Link to Portfolio */}
                  <div>
                    <label
                      htmlFor="nft_artist_name"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Link to Portfolio
                    </label>
                    <input
                      type="url"
                      name="artist_portfolio_link"
                      id="artist_portfolio_link"
                      placeholder="Link to Portfolio"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      onChange={(e) => handleNFTDetailsChange(e)}
                    />
                  </div>

                  {/* Wallet Address */}
                  <div>
                    <label
                      htmlFor="nft_artist_name"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Wallet Address
                    </label>
                    <input
                      readOnly
                      type="text"
                      name="artist_wallet_address"
                      id="artist_wallet_address"
                      placeholder="Wallet Address"
                      className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-not-allowed "
                      value={currentAccount}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-[#2692ff] hover:bg-[#0758a7] focus:ring-4 focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 text-center font-OwnersWide font-bold "
                  >
                    Upload NFT
                  </button>
                </form>

              </div>
            </div>
          </div>
        </div>
      )}
    </>

  );
}

export default FormModal;
