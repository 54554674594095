import { useContext, useState, useRef, useEffect } from "react";

import { TransactionContext } from "../context/TransactionsContext";
import { useGetFetch } from "../hooks/useGetFetch";

import Accordion from "../components/Accordian";
import HamburgerMenuCard from "../components/HamburgerMenuCard";
import ImageCarousel from "../components/ImageCarousel";
import FormModal from "../components/FormModal";
import CircularBtn from "../components/CircularBtn";

import GhothamLabs from "../assets/images/Gotham-Logo.png";
import ChooseLove from "../assets/images/Choose-Love-Logo.png";
import SunflowerHeart from "../assets/images/SunflowerHeart.png";
import Donate_Sunflower from "../assets/images/Donate_Sunflower.png";
import Donate_Sunflower_Mobile from "../assets/images/Donate_Sunflower_Mobile.png";
import About_Sunflower from "../assets/images/About_Sunflower.png";

import NFT1 from "../assets/images/NFT1.svg";
import { ReactComponent as GalaxyLabs } from "../assets/images/Galaxy-Logo.svg";
import { ReactComponent as InstagramLogo } from "../assets/images/instagram.svg";
import { ReactComponent as TwitterLogo } from "../assets/images/twitter.svg";
import { ReactComponent as SunflowersLogo } from "../assets/images/SunFlower-Logo.svg";

import { API_BASE_URL } from "../constant/apiConstants";
import { faqs, submitYourArtLink } from "../constant/constant";
import Navbar from "../components/Navbar";
import WalletModal from "../components/WalletModal";
import SuccessfulModal from "../components/SuccessfulModal";
import FeaturedArtist from "../components/FeaturedArtist";

const HomePage = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const ref = useRef([]);

  const {
    mintToken,
    isModalOpen,
    currentAccount,
    connectWalletModal,
    setConnectWalletModal,
    isSubmitYourModalArtOpen,
    setIsSubmitYourModalArtOpen,
  } = useContext(TransactionContext);

  let [nftArray, isNftArrayLoading] = useGetFetch(API_BASE_URL);
  let [featuredArtist, isFeaturedArtistLoading] = useGetFetch(API_BASE_URL + '?is_featured=true');
  const scrollToTheSection = (id) => {
    ref.current[id].scrollIntoView(true);
    setIsHamburgerMenuOpen(false);
  };

  return (
    <>
      {isHamburgerMenuOpen && (
        <HamburgerMenuCard handleOnMenuBtnClick={scrollToTheSection} />
      )}
      {isModalOpen && <SuccessfulModal />}
      {connectWalletModal && <WalletModal />}

      <section className="homepage-hero-section p-5">
        <Navbar
          isHamburgerMenuOpen={isHamburgerMenuOpen}
          setIsHamburgerMenuOpen={setIsHamburgerMenuOpen}
        />
        <div className="flex justify-center pt-9  ">
          <div>
            <img
              src={SunflowerHeart}
              className="mx-auto"
              alt="Sunflower Heart"
            />
            <h1 className="text-white font-OwnersWide font-bold text-2xl md:text-4xl tracking-wide mt-5 text-center">
              Sunflowers DAO
            </h1>
          </div>
        </div>

        <div className="mt-20 mb-36 w-100 mx-auto ">
          <ImageCarousel slides={nftArray} />
          <div className="flex justify-center">
            <button
              className="customBlue rounded-full text-white
             w-80 py-3 mt-6
             text-xl mb-16  font-OwnersWide font-bold tracking-wide"
            >
              VIEW AUCTION
            </button>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section
        ref={(el) => (ref.current[0] = el)}
        className="bg-white  text-black pb-10 md:pb-0  md:flex md:pr-20 lg:h-screen "
      >
        <img
          src={About_Sunflower}
          alt="about"
          className="relative md:block h-80 md:h-full  bottom-32 md:bottom-72"
        />
        <div className="new-container -mt-32 md:mt-56  flex-col text-left">
          <p className="font-OwnersWide font-black text-3xl md:text-5xl  text-center md:text-left">About</p>
          <div className="font-WorkSans md:text-xl   tracking-wider mt-4 md:mt-8 md:w-4/5">
            <div>
              The crisis in Ukraine has already produced 2 million refugees. The
              Sunflowers DAO - led by a coalition of artists, activists, and
              influencers - is an effort to support those displaced by the
              crisis.
              <br />
              <br />
              <p className="font-bold">
                All revenue generated by Sunflowers DAO will be used to fund the
                Ukranian crisis response work of Choose Love, a global leader in
                refugee aid.
              </p>
              <br />
              Supporters can bid on 1 of 1 pieces from high profile artists,
              mint our more affordable People’s Sunflower, or directly donate
              Fiat or Crypto.
            </div>
          </div>
        </div>
      </section>

      {/* Minting section */}
      <section
        id="Mint"
        ref={(el) => (ref.current[1] = el)}
        className="customBlue pt-16 md:pt-32 flex justify-center text-center text-white"
      >
        <div className="flex flex-col">
          <p className="font-OwnersWide font-bold text-2xl md:text-4xl">
            The People’s Sunflower
          </p>
          <p className="my-5 md:my-10  w-3/4 md:w-1/2 font-WorkSans text-base md:text-lg tracking-wide  mx-auto">
            This affordable NFT was created so people across the world could
            show that they were not only standing with Ukraine, but also moving
            resources to help those displaced by the crisis.
          </p>
          <div className="flex justify-center background-ribbon">
            <img src={NFT1} className="h-40 md:h-80" alt="NFT" />
          </div>
          <p className="font-OwnersWide font-extrabold mt-10 text-2xl tracking-wider">
            0.1 ETH
          </p>
          {currentAccount ? (
            <button
              onClick={() => mintToken()}
              className="bg-black mx-auto rounded-full text-white
              hover:opacity-90 w-[360px] py-3 mt-6 
               text-lg md:text-xl mb-16 font-OwnersWide font-bold uppercase"
            >
              Mint Now
            </button>
          ) : (
            <button
              onClick={() => setConnectWalletModal(true)}
              className="bg-black mx-auto rounded-full text-white
              w-[360px] py-3 mt-6 
               text-lg md:text-xl mb-16 font-OwnersWide font-bold uppercase"
            >
              Connect Wallet
            </button>
          )}
        </div>
      </section>

      {/* Donate Section */}
      <section
        ref={(el) => (ref.current[2] = el)}
        className="bg-white pb-5 md:pb-0 pl-5 md:pl-20 flex justify-between"
        id="Donate"
      >
        <div className="md:my-auto">
          <p className="text-blue-500 font-OwnersWide text-2xl mt-3 md:mt-0 md:text-4xl font-bold">
            Donate
          </p>
          <p className="font-WorkSans text-xs md:text-lg  pb-5">
            Donations go straight to our partner, Choose Love.
          </p>
          <a
            href="https://donate.chooselove.org/campaigns/sunflowers-4-ukraine/"
            rel="noreferrer"
            target="_blank"
            className="customYellow rounded-full
            font-bold text-xd md:text-xl font-OwnersWide uppercase tracking-wide
            py-2 px-5 whitespace-nowrap
            "
          >
            Donate In Fiat
          </a>
        </div>
        <div className="object-fit">
          <img
            src={Donate_Sunflower_Mobile}
            className="md:hidden h-36 -mt-3  -mb-10 object-cover"
            alt="donate"
          />
          <img
            src={Donate_Sunflower}
            className="hidden md:block h-36 md:h-full l"
            alt="donate"
          />
        </div>
      </section>

      {/* Submit your art  */}
      <section
        ref={(el) => (ref.current[3] = el)}
        className="customBlue mt-10 md:mt-0 py-12 md:pb-28 "
        id="Submit Your Art"
      >
        {featuredArtist && <FeaturedArtist nftArray={featuredArtist} />}

        <div className="new-container hidden">
          <h1 className="font-OwnersWide text-center text-3xl md:text-4xl font-bold text-white ">
            Create
          </h1>
          <p className="text-white text-md md:text-xl w-full md:w-3/4 conte mx-auto font-WorkSans md:px-10 text-center mb-5 md:my-10 ">
            We’ll soon be launching the <b>Sunflowers DAO</b> as a platform
            where any artist can create and sell a Sunflower NFT to support
            those affected by this crisis.
          </p>
          <div className="flex justify-center">
            {currentAccount ? (
              <button
                onClick={() => setIsSubmitYourModalArtOpen(true)}
                className="bg-black text-white opacity-90 py-2 px-6 md:px-10 text-center rounded-full
                border-black font-bold font-OwnersWide uppercase tracking-wide text-xs"
              >
                SUBMIT YOUR ART
              </button>
            ) :
              <button
                onClick={() => setConnectWalletModal(true)}
                className="bg-black text-white opacity-90 py-2 px-6 md:px-10 text-center rounded-full
                border-black font-bold font-OwnersWide uppercase tracking-wide text-xs md:text-lg "
              >
                SUBMIT YOUR ART
              </button>
            }

            {isSubmitYourModalArtOpen && (
              <FormModal
                setIsSubmitYourModalArtOpen={setIsSubmitYourModalArtOpen}
              />
            )}
          </div>
        </div>
      </section>

      {/* FAQ */}
      <section
        ref={(el) => (ref.current[4] = el)}
        className="text-[#3A99F6] py-20"
      >

        <h1 className="font-OwnersWide text-center text-3xl md:text-4xl font-bold ">
          FAQs
        </h1>
        <div className="new-container pt-5 pb-10 md:py-20">
          {faqs.map((faqInfo, idx) => (
            <div key={idx}>
              <Accordion
                heading={faqInfo.question}
                headingClass=" text-[#3A99F6] font-Degular text-left text-lg p-5"
              >
                <div
                  className=" pt-2 pb-5 px-5 font-Degular text-lg opacity-90"
                  dangerouslySetInnerHTML={{ __html: faqInfo.answer }}
                />
              </Accordion>
            </div>
          ))}
        </div>
      </section>

      {/* Partners */}
      <section
        ref={(el) => (ref.current[5] = el)}
        id="Partners"
        className="customBlue py-20 md:py-20"
      >
        <h1 className="font-OwnersWide uppercase text-center text-2xl font-semibold text-white">
          LAUNCH PARTNERS
        </h1>
        <div className="new-container  md:flex justify-center mt-14">
          <a href="https://choose.love/" target="_blank" rel="noreferrer">
            <img
              src={ChooseLove}
              className="mx-auto my-10 md:mx-5 md:my-0 h-16 cursor-pointer"
              alt="Choose Love"
            />
          </a>
          <a href="https://www.galaxylabs.io/" target="_blank" rel="noreferrer">
            <GalaxyLabs className="mx-auto my-10 md:mx-5 md:my-0 h-16 cursor-pointer" />
          </a>
          <a href="https://www.gothamlabs.co" target="_blank" rel="noreferrer">
            <img
              src={GhothamLabs}
              className="mx-auto my-10 md:mx-5 md:my-0 h-16 cursor-pointer"
              alt="Ghotham Labs"
            />
          </a>
        </div>
      </section>

      {/*Footer  */}
      <footer className="bg-black py-10 ">
        <div className="new-container">
          <SunflowersLogo className="mx-auto w-32 h-20 md:w-48 md:h-full" />
          <h1 className="text-white max-auto text-center text-xl md:text-2xl font-bold font-OwnersWide">
            Sunflowers DAO
          </h1>
          <div className="flex flex-row justify-center my-6 md:my-10">
            <CircularBtn
              onClick={() => scrollToTheSection(1)}
              additionalClasses="text-black rounded-full bg-white
             px-4 py-1 md:px-10 md:py-1
             font-OwnersWide font-bold text-[10px] whitespace-nowrap md:text-base mr-5 "
              text="MINT"
            />
            <a
              href="mailto:contact@sunflowers4ukraine.org"
              className="bg-[#2692ff] rounded-full text-white
             px-4 py-1 md:px-10 md:py-1
             font-OwnersWide font-bold text-[10px] whitespace-nowrap md:text-base mr-5 "
            >
              CONTACT US
            </a>
            <button
              onClick={() => scrollToTheSection(2)}
              className="customYellow rounded-full text-black
             px-4 py-1 md:px-10 md:py-1
             font-OwnersWide font-bold text-[10px] whitespace-nowrap  md:text-base "
            >DONATE
            </button>
          </div>
          <div className="flex flex-row justify-center">
            <a href="#" target='_blank' rel="noreferrer">
              <InstagramLogo className="mr-5 cursor-pointer" />
            </a>
            <a href="https://twitter.com/SunflowersDao" target='_blank' rel="noreferrer">
              <TwitterLogo className=" cursor-pointer" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomePage;
