import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./index.css";
import HomePage from "./pages/HomePage";
import {TransactionProvider} from './context/TransactionsContext'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <div>
      <TransactionProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <HomePage />
              
            </Route>
          </Switch>
        </Router>
        <ToastContainer />
      </TransactionProvider>
    </div>
  );
}

export default App;
