export const Navsections=["About","Mint","Donate Fiat","Create","FAQs","Partners"]
export const faqs = [
  {
    question: "Who launched Sunflowers DAO?",
    answer:
      "Sunflowers DAO is being put forth by a coalition of reputable nonprofits, foundations, and web3 communities. The project was created via a partnership between Galaxy Labs, Gotham Labs, and Choose Love.      ",
  },
  {
    question: "How Do I Know This Money Supports Ukraine? ",
    answer:
      "All revenue generated by the Sunflowers DAO auction will be passed on to efforts on the ground vetted by our partner <a class='underline' href='https://choose.love/pages/about-choose-love' target='_blank'>Choose Love,</a> a global leader in vetting and funding local efforts that support displaced people and those living in conflict.      ",
  },
  {
    question: "How Do I Participate?",
    answer:
      "Auctions are being held on Nifty Gateway.",
  },
  {
    question: "Can I Donate Without Crypto? ",
    answer:
      "Yes. Choose Love is accepting donations in USD. Click here to <a class='underline' href='https://choose.love/pages/about-choose-love' target='_blank'>donate.</a>",
  },

];

export const submitYourArtLink = "https://google.com";
