import React, { useContext } from "react";
import { TransactionContext } from "../context/TransactionsContext";

const SuccessfulModal = () => {

  const {
    isModalOpen,setIsModalOpen
  } = useContext(TransactionContext);
  return (
    <div className=" ">
      {isModalOpen && (
          <div
            id="walletModal"
            onClick={() => setIsModalOpen(false)}
            className="h-screen w-screen overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-0 flex align-middle z-50 justify-center items-center  md:h-full md:inset-0 bg-black bg-opacity-40"
          >
            <div className=" flex align-middle justify-center text-center  px-4 w-full max-w-md  mx-auto">
              {/* <!-- Modal content --> */}
              <div className="relative  rounded-lg shadow bg-[#1f1f1f]">
                {/* <!-- Modal header --> */}
                <div className=" py-4 px-6 rounded-t  bg-[#0758A7] ">
                  <h3 className="text-base font-EuroBold font-semibold text-white lg:text-xl text-center">
                    MINT SUCCESSFUL
                  </h3>
                </div>
                {/* <!-- Modal body -->  */}
                <div className="p-6 bg-[#E5C837] text-[#0758A7]">
                  <p className="text-sm font-normal  ">
                    FOLLOW US ON <a href='https://twitter.com/SunflowersDao' target='_blank' className="underline">TWITTER</a> FOR THE LATEST UPDATES ABOUT WHEN WE’LL
                    DEPLOY CAPITAL
                  </p>
                  <ul className="my-4 space-y-3">
                    <li>
                      <a
                        href="https://twitter.com/intent/tweet?url=https://www.sunflowers4ukraine.org&text=%F0%9F%8C%BB%20%F0%9F%87%BA%F0%9F%87%A6%20I%20just%20supported%20@SunflowersDAO%20and%20@ChooseLove%20by%20minting%20a%20Sunflower%204%20Ukraine.%20Join%20the%20movement."
                        target='_blank'
                        className=" pb-2 pt-3 pl-1 pr-3 text-base font-EuroNormal tracking-widest   rounded-lg  bg-[#0758A7]  "
                      >
                        <span className="flex-1 ml-3 whitespace-nowrap text-[#E5C837]">
                          SHARE YOUR SUPPORT
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      )}
    </div>
  );
};

export default SuccessfulModal;
